export const useFormatters = () => {
    // Format currency to USD
    function formatUSD(v) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v)
    }

    function getFullMonthAndDate(v) {
        const event = new Date(v)
        // User month: short for 3 char month
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        return event.toLocaleDateString('en-US', options)
    }

    function getMonthAndYear(v) {
        const event = new Date(v)
        // User month: short for 3 char month
        const options = { year: 'numeric', month: 'long' }
        return event.toLocaleDateString('en-US', options)
    }

    /**
     * Returns the number of days left until the supplied date
     * Format for input date = 2024-11-04 11:59:59
     */
    function getDaysLeft(v) {
        const daysLeft = Math.round((new Date(v) - new Date()) / (1000 * 3600 * 24))
        if (daysLeft === 1) {
            return daysLeft + ' day left'
        } else if (new Date(v).getFullYear() > 9998) {
            return 'Forever'
        } else if (daysLeft > 1) {
            return daysLeft + ' days left'
        } else {
            return 'Expired'
        }
    }

    /**
     * Returns the number of days left until the supplied date in numeric
     * Format for input date = 2024-11-04 11:59:59
     */
    function getDaysLeftNumber(v) {
        const daysLeft = Math.round((new Date(v) - new Date()) / (1000 * 3600 * 24))
        return daysLeft
    }

    /**
     * Returns the number of days left until the supplied date
     * Format for input date = 2024-11-04 11:59:59
     */
    function getTimeLeft(v) {
        const total = Date.parse(new Date(v)) - Date.parse(new Date())
        const seconds = Math.floor((total / 1000) % 60)
        const minutes = Math.floor((total / 1000 / 60) % 60)
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
        const days = Math.floor(total / (1000 * 60 * 60 * 24))

        return {
            days,
            hours,
            minutes,
            seconds,
        }
    }

    return { formatUSD, getFullMonthAndDate, getMonthAndYear, getDaysLeft, getDaysLeftNumber, getTimeLeft }
}
